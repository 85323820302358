<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/list">List</a>
    </div>
    <div class="row issue-wrapper">
      <div class="col-md-8 issue-col bg-white">
        <div class="row ticket-actions">
          <div class="col left-column">
            <div class="ticket-tab" v-on:click="setParamsList('urgent')" v-bind:class="[ticketTab === 'urgent' ? 'active' : '']">
              Urgent
            </div>
            <div class="ticket-tab" v-on:click="setParamsList('inactive')" v-bind:class="[ticketTab === 'inactive' ? 'active' : '']">
              Inactive
            </div>
          </div>
        </div>
        <TicketListComponent
          :tickets="tickets"
          :ticketCount="tickets.length"
          :page="1"
          :busy="true"
        ></TicketListComponent>
      </div>
      <div class="col-md-4 issue-col border-md-left p-4 bg-white">
        <h6 class="py-2 text-header">Recent Activities</h6>
        <small v-if="histories.length > 0">
          <a class="row text-left p-3 hover-light cursor-pointer border-bottom" v-for="history in histories" v-bind:key="history._id" :href="`/tickets/${history.ticket.ticketNumber}`">
            <div class="col-auto p-0" v-tooltip="history.ticket.client.name">
              <img class="activity-avatar cursor-pointer" :src="history.ticket.client.logo">
            </div>
            <div class="col p-0">
              <label class="text-property text-bold cursor-pointer">
                <span v-html="history.messages"></span><br>
                <small class="text-secondary">{{history.ticket.ticketNumber}} | {{history.createdAt | dateEstimate}}</small>
              </label>
            </div>
          </a>
        </small>
        <div class="p-2" v-else>
          <p class="text-center text-muted mt-1">
            No recent activities&nbsp;&nbsp;<i class="fas fa-info-circle"></i>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import TicketListComponent from './TicketList.vue'

  export default {
    components: {
      TicketListComponent
    },
    data() {
      return {
        user: this.$userData ? this.$userData.user : {},
        tickets: [],
        ticketTab: 'urgent',
        histories: []
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      async getList(){
        this.loader(true);
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/tickets/list`,
            {
              tab: this.ticketTab
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.tickets = response.data.tickets
          this.histories = response.data.histories.map(record => {
            return {
              ...record,
              messages: this.buildMessage(record)
            }
          }).filter(r => r.messages.length > 0)
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      setParamsList: function(val){
        this.ticketTab = val;
        this.tickets = []
        this.getList();
      },
      buildMessage: function (record){
        let messages = []
        let ticket = record.ticket
        let details = record.details
        if(record.action === 'Create')
          messages.push(`Created new ticket - ${ticket.ticketNumber}`)
        else
          if(ticket && ticket.client && details && record.details.v1){
            if(details.status === 'pending' && details.iterationCount === 1)
              messages.push(`Created new ticket - ${ticket.ticketNumber}`)
            else if(details.status === 'pending' && details.iterationCount > 1){
              messages.push(`Marked ${ticket.ticketNumber} as an open issue.`)
              messages.push(`Ticket has been moved to ${details.status !== 'inProgress' ? details.status : 'in progress'}`)
            }
            else if(details.status === 'closed')
              messages.push(`Closed ticket ${ticket.ticketNumber}`)
            else if(details.status && details.status !== 'closed')
              messages.push(`Changed status of ${ticket.ticketNumber} to ${details.status !== 'inProgress' ? details.status : 'in progress'}`)
            if(details.agent && details.pre && details.pre.agent && record.agent && record.agent._id != details.pre.agent._id)
              messages.push(`Reassigned ${ticket.ticketNumber} to ${record.agent.name}`)
          }
        return messages.join('<br>')
      }
    },
    filters: {
      dateEstimate(date) {
        return moment(date, 'YYYY/MM/DD HH:mm:ss').fromNow();
      }
    },
    mounted: function() {
      document.title = `My list | iRipple Helpdesk`
      this.getList()
    }
  }
</script>
