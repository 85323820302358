<template>
  <div class="ticket-holder">
    <div v-infinite-scroll="getTickets" infinite-scroll-disabled="busy" infinite-scroll-distance="1000" infinite-scroll-throttle-delay="2000">
      <li class="row" v-for="ticket in tickets" v-bind:key="ticket._id">
        <a class="col col-12 row ticket-details pointer" :href="`/tickets/${ticket.ticketNumber}`">
          <div class="col col-auto-8 left-column">
            <div class="ticket-header">
              <span class="cursor-pointer" v-tooltip="ticket.isActive ? `Ticket is currently active` : `Ticket has been inactive for a while`">
                <i class="far fa-check-circle" v-bind:class="ticket.isActive ? `text-grass` : `text-secondary`"></i>
              </span>&nbsp;
              <span v-if="$userData.user.role == 'Customer' && ticket.client.code">[{{ticket.ticketNumber}}]</span>
              <span v-else>[{{ticket.ticketNumber}}]</span>
              {{ticket.issue}}
            </div>
            <div class="small" style="padding-left: 25px" v-if="$userData.user.role != 'Customer'">{{ticket.client.name}}</div>
            <div class="small mt-1" style="padding-left: 25px">
              <div v-if="ticket.acknowledgedAt">
                <span class="cursor-pointer">
                  <i class="text-secondary far fa-calendar-plus"></i>
                </span>&nbsp;
                <span>
                  Responded within {{$getDuration(ticket, 'acknowledgedAt')}}
                </span>
              </div>
              <div v-if="ticket.resolvedAt">
                <span class="cursor-pointer">
                  <i class="text-secondary far fa-calendar-check"></i>
                </span>&nbsp;
                <span>
                  Resolved within {{$getDuration(ticket, 'resolvedAt')}}
                </span>
              </div>
              <div>
                <span class="cursor-pointer">
                  <i class="text-secondary far fa-calendar"></i>
                </span>&nbsp;
                <span class="pointer" v-tooltip="formatDate(ticket.createdAt)">
                  Reported {{ticket.createdAt | dateEstimate }}
                </span>
              </div>
              <div>
                <span class="cursor-pointer">
                  <i class="text-secondary far fa-calendar-alt"></i>
                </span>&nbsp;
                <span class="pointer" v-tooltip="formatDate(ticket.updatedAt)">
                  Last updated {{ticket.updatedAt | dateEstimate }}
                </span>
              </div>
              <div class="d-block d-xl-none d-lg-none d-md-none border-top mt-1 pt-1">
                <span class="cursor-pointer">
                  <i class="text-secondary far fa-clipboard"></i>
                </span>&nbsp;
                <span>
                  {{$statusTitle[ticket.status]}} | {{ticket.priority | priorityName }}
                </span>
              </div>
            </div>
          </div>
          <div class="col col-4 right-column">
            <div class="d-none d-xl-block d-lg-block d-md-block small">
              <label v-bind:class="['bg-' + ticket.status]">{{ticket.status}}</label>
              <label v-bind:class="['bg-priority-' + ticket.priority]">{{ticket.priority | priorityName }}</label>
              <label v-if="ticket.issueClassification" v-bind:class="['bg-' + ticket.status]">{{ticket.issueClassification}}</label><br>
              <label class="bg-primary-default" v-for="label in ticket.labels" v-bind:key="label">{{label}}</label>
              <br>
              <div class="counter d-block pointer">
                <i class="fas fa-history"></i>
                <span v-tooltip="ticket.iterationCount + ' iteration count'">
                  {{ ticket.iterationCount }} Iteration Count
                </span>
              </div>
              <div class="counter d-block pointer" v-if="ticket.agent">
                <img class="header-avatar ticket-list-avatar" :src="$getAvatar(ticket.agent.avatar)">
                <span v-tooltip="'Assigned to ' + ticket.agent.name" v-on:click.stop="openUser(ticket.agent)">
                  Assigned to {{ticket.agent.name}}
                </span>
              </div>
              <div class="counter d-block pointer" v-if="ticket.gitLabId">
                <img class="header-avatar ticket-list-avatar" :src="$getAvatar(`/images/avatars/gitAvatar.svg`)">
                <span v-tooltip="`Escalated to ${ticket.project.gitLab.title} - Ticket No. ${ticket.gitLabId}`">
                  Escalated to GitLab
                </span>
              </div>
            </div>
            <div class="d-block d-xl-none d-lg-none d-md-none">
              <div class="my-5 px-2 fa fa-chevron-right"/>
            </div>
          </div>
        </a>
      </li>
      <li v-if="!busy" class="row">
        <h6 class="col col-12 text-muted text-center p-3">
          Loading more tickets &nbsp;
          <h6 class="fas fa-spinner animate-spin"></h6>
        </h6>
      </li>
      <li v-if="tickets.length === 0" class="vertical-center h-100">
        <h6 class="col col-12 text-muted text-center p-3">
          No available tickets &nbsp;
          <h6 class="fas fa-exclamation-circle"></h6>
        </h6>
      </li>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    props: {
      tickets: Array,
      ticketCount: Number,
      busy: Boolean,
      username: Number
    },
    methods: {
      getTickets: function(){
          this.$emit('getTickets', true)
      },
      hideSideOptions: function(){
          this.$emit('hideSideOptions', 'showTicketsOptions')
      },
      formatDate: function (date){
        return moment(date, 'YYYY/MM/DD HH:mm:ss').format("MMM DD, YYYY hh:mm A")
      },
      openTicket: function(ticket){
        window.location.href = `/tickets/${ticket.ticketNumber}`
      },
      openUser: function(user){
        this.hideSideOptions()
        if (user.roles === 'Manager')
          window.location.href = `/managers/${user._id}`
        else if (user.roles === 'Agent')
          window.location.href = `/agents/${user._id}`
      }
    },
    filters: {
      dateEstimate(date) {
        return moment(date, 'YYYY/MM/DD HH:mm:ss').fromNow();
      },
      priorityName(val) {
        switch(parseInt(val)){
          case 1: return 'Critical'
          case 2: return 'Major'
          case 3: return 'Moderate'
          case 4: return 'Low'
          default: return 'Unclassified'
        }
      }
    }
  }
</script>
